module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="banner">\r\n    <img src="' +
((__t = ( require('./images/search-banner.png') )) == null ? '' : __t) +
'" alt="">\r\n    <div class="banner-content">\r\n        <h1 class="banner-title">搜索结果</h1>\r\n        <p class="banner-text">SEARCH RESULT</p>\r\n        <span></span>\r\n    </div>\r\n</div>\r\n';

}
return __p
}