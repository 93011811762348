module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="page">\r\n    <a href="javascript:;" class="previous">\r\n        <img src="' +
((__t = ( require('./images/page-left.png') )) == null ? '' : __t) +
'" alt="" class="img1">\r\n		<img src="' +
((__t = ( require('./images/page-left1.png') )) == null ? '' : __t) +
'" alt="" class="img2">\r\n    </a>\r\n    <a href="javascript:;" class="active">01</a>\r\n    <a href="javascript:;">02</a>\r\n    <a href="javascript:;">03</a>\r\n    <a href="javascript:;" class="next">\r\n        <img src="' +
((__t = ( require('./images/page-right.png') )) == null ? '' : __t) +
'" alt="" class="img1">\r\n		<img src="' +
((__t = ( require('./images/page-right1.png') )) == null ? '' : __t) +
'" alt="" class="img2">\r\n    </a>\r\n</div>';

}
return __p
}