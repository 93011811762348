import { find } from 'lodash';
import './index.scss'
$(".list-button").click(function() {
    $(this).addClass("active").siblings().removeClass("active");
})
$(".minimize").click(function() {
    $(".select").hide()
    $(".select-button").show()
    $(".select-model").hide()
})
$(".select-model").click(function() {
    $(".select").hide()
    $(".select-button").show()
    $(".select-model").hide()
})
$(".select-button").click(function() {
    $(".select").show()
    $(this).hide()
    $(".select-model").show()
})

// $(".option").on("click",'dd',function() {
//     if($(this).hasClass("active")) {
//         $(this).removeClass("active")
//     }else {
//         $(this).addClass("active").siblings().removeClass("active");
        
//     }
// })
// $(".list").on("click",'li',function() {
//     let id1 = $(this).attr("id")
//     let selectedList = $(".selected")
//     let sele = selectedList.find('#'+id1)
//     let selected = $(".selected ul")
//     let li = ''
    
//     if($(this).find('input').is(':checked')) {
//         $(this).find('input').attr('checked', false);
//         sele.remove();
//         $(this).removeClass("on")
//     }
//     else {
//         $(this).find('input').attr('checked', true);
//         li=document.createElement('li');
//         li.id = id1;
//         sele.css("display","flex");
//         li.innerHTML=this.innerHTML;
//         selected.append(li);
//         $(this).addClass("on")
//     }
// })
// $(".button .submit").click(function() {
//     let inputList = ''
//     $(".list li").each(function() {
//         if($(this).hasClass('on')) {
//             inputList += $(this).attr('id') + ','
//             console.log(inputList);
//             $(".button input").attr("value",inputList);
//         }
//     })   
// })

// $(".selected").on("click",'.out',function(){
//     let listid = $(this).parent().attr("id")
//     let selectList = $(".list ul");
//     let select = selectList.find('#'+listid);
//     $(this).parent().remove();
//     select.find('input').attr('checked', false);
// })
// $(".button .reset").click(function() {
//     $("from li input").val("");
//     $("from textarea").val("");
//     $(".on input").attr('checked', false);
//     $(".selected ul li").remove();
//     $(".option dd").removeClass("active")
// })

// if ($(window).width() > 768) {
//     $(".list").on("mouseover",'li',function() {
//         $(".magnifier-view").show();
//         let id1 = $(this).attr("id")
//         let viewList = $(".magnifier-view")
//         let view = viewList.find('#'+id1)
//         let li = ''
//         li=document.createElement('li');
//         li.id = id1;
//         view.show();
//         li.innerHTML=this.innerHTML;
//         viewList.append(li);
//         $(this).addClass("on")

//     })
//     $(".list").on("mouseout",'li',function() {
//         let id1 = $(this).attr("id")
//         let viewList = $(".magnifier-view")
//         let view = viewList.find('#'+id1)
//         $(".magnifier-view").hide();
//         view.remove();
//     })
// }