module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="banner">\r\n    <img src="' +
((__t = ( require('./images/contact-banner.png') )) == null ? '' : __t) +
'" alt="">\r\n    <div class="banner-content">\r\n        <h1 class="banner-title">联系我们</h1>\r\n        <p class="banner-text">CONTACT US</p>\r\n        <span></span>\r\n    </div>\r\n</div>\r\n<div class="content-nav">\r\n    <div class="container">\r\n        <a href="contact.html">联系我们</a>\r\n    </div>\r\n</div>';

}
return __p
}