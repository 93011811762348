module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="select-button">\r\n    <img src="' +
((__t = ( require('./images/select-button.png') )) == null ? '' : __t) +
'" alt="">\r\n</div>';

}
return __p
}