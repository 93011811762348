module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="banner">\r\n    <img src="' +
((__t = ( require('./images/about-banner.png') )) == null ? '' : __t) +
'" alt="">\r\n    <div class="banner-content">\r\n        <h1 class="banner-title">关于我们</h1>\r\n        <p class="banner-text">about us</p>\r\n        <span></span>\r\n    </div>\r\n</div>\r\n<div class="content-nav">\r\n    <div class="container">\r\n        <a href="company-profile.html">公司简介</a>\r\n        <a href="history.html">发展历程</a>\r\n        <a href="shareholder.html">股东介绍</a>\r\n    </div>\r\n</div>';

}
return __p
}