module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<footer>\r\n    <div class="footer-top">\r\n        <div class="container">\r\n            <ul class="top">\r\n                <li class="footer-logo">\r\n                    <a href="index.html">\r\n                        <img src="' +
((__t = ( require('./images/footer-logo.png') )) == null ? '' : __t) +
'" alt="">\r\n                    </a>\r\n                    <p class="text">青岛领军智能建造新材料科技有限公司</p>\r\n                </li>\r\n                <div class="shu"></div>\r\n                <li>\r\n                    <div class="footer-icon">\r\n                        <img src="' +
((__t = ( require('./images/address.png') )) == null ? '' : __t) +
'" alt="">\r\n                    </div>\r\n                    <div class="text">\r\n                        <h2 class="title">地址：</h2>\r\n                        <p class="text">青岛市平度市田庄镇光大路7号</p>\r\n                    </div>\r\n                </li>\r\n                <div class="shu"></div>\r\n                <li>\r\n                    <div class="footer-icon">\r\n                        <img src="' +
((__t = ( require('./images/phone.png') )) == null ? '' : __t) +
'" alt="">\r\n                    </div>\r\n                    <div class="text">\r\n                        <h2 class="title">电话：</h2>\r\n                        <p class="text">0532-57751977</p>\r\n                    </div>\r\n                </li>\r\n                <div class="shu"></div>\r\n                <li class="ewm">\r\n                    <div class="li-text">\r\n                        <p class="text">微信公众号</p>\r\n                    </div>\r\n                    <img src="' +
((__t = ( require('./images/footer-ewm.png') )) == null ? '' : __t) +
'" alt="">\r\n                </li>\r\n            </ul>\r\n            <div class="right-bottom">\r\n                <p class="text">青岛领军智能建造新材料科技有限公司</p>\r\n                <div class="bottom-nav">\r\n                    <a href="javascript:;">关于我们</a>\r\n                    <a href="javascript:;">产品与定制</a>\r\n                    <a href="javascript:;">创新技术</a>\r\n                    <a href="javascript:;">工程案例</a>\r\n                    <a href="javascript:;">新闻中心</a>\r\n                    <a href="javascript:;">加入我们</a>\r\n                    <a href="javascript:;">联系我们</a>\r\n                </div>\r\n            </div>\r\n        </div>\r\n    </div>\r\n    <div class="footer-bottom">\r\n        <p>\r\n            Copyright ©2022青岛领军智能建造新材料科技有限公司 All Rights Reserved.\r\n            <a href="javascript:;">鲁ICP备2022040918号</a>\r\n            网站建设 丨\r\n            <a href="https://dongbaqu.com/">东八区网络</a>\r\n        </p>\r\n    </div>\r\n</footer>';

}
return __p
}