module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="banner">\r\n    <img src="' +
((__t = ( require('./images/product-banner.png') )) == null ? '' : __t) +
'" alt="">\r\n    <div class="banner-content">\r\n        <h1 class="banner-title">产品与定制</h1>\r\n        <p class="banner-text">PRODUCT AND CUSTOMIZATION</p>\r\n        <span></span>\r\n    </div>\r\n</div>\r\n<div class="content-nav">\r\n    <div class="container">\r\n        <a href="product1.html">领军无机板</a>\r\n        <a href="product2.html">领军预涂板</a>\r\n        <a href="product3.html">领军匀质板</a>\r\n        <a href="product4.html">领军一体板</a>\r\n        <a href="product5.html">轻质隔墙板</a>\r\n        <a href="product6.html">领军生态砖石</a>\r\n        <a href="product7.html">超细粉掺合料</a>\r\n    </div>\r\n</div>';

}
return __p
}